// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-new-form-js": () => import("./../../../src/pages/new-form.js" /* webpackChunkName: "component---src-pages-new-form-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/AboutPage.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/BlogIndex.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-boats-page-js": () => import("./../../../src/templates/BoatsPage.js" /* webpackChunkName: "component---src-templates-boats-page-js" */),
  "component---src-templates-booking-js": () => import("./../../../src/templates/Booking.js" /* webpackChunkName: "component---src-templates-booking-js" */),
  "component---src-templates-booking-thank-you-js": () => import("./../../../src/templates/BookingThankYou.js" /* webpackChunkName: "component---src-templates-booking-thank-you-js" */),
  "component---src-templates-case-studies-js": () => import("./../../../src/templates/CaseStudies.js" /* webpackChunkName: "component---src-templates-case-studies-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-cruises-js": () => import("./../../../src/templates/Cruises.js" /* webpackChunkName: "component---src-templates-cruises-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/DefaultPage.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-private-charters-js": () => import("./../../../src/templates/PrivateCharters.js" /* webpackChunkName: "component---src-templates-private-charters-js" */),
  "component---src-templates-single-boat-tour-js": () => import("./../../../src/templates/SingleBoatTour.js" /* webpackChunkName: "component---src-templates-single-boat-tour-js" */),
  "component---src-templates-single-case-study-js": () => import("./../../../src/templates/SingleCaseStudy.js" /* webpackChunkName: "component---src-templates-single-case-study-js" */),
  "component---src-templates-single-faq-page-js": () => import("./../../../src/templates/SingleFaqPage.js" /* webpackChunkName: "component---src-templates-single-faq-page-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/SinglePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

